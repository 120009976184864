import {
  CREATE_PARTNER_REQUEST,
  GET_PARTNERS_FOR_TABLE_REQUEST,
  SET_PAGE_PAGINATION,
  GET_PARTNER_DATA_REQUEST,
  UPDATE_PARTNER_REQUEST,
  CLEAR_EDITOR_FORM,
  ADD_NEW_IP,
  DEL_IP,
  CHANGE_ACCESS,
} from '../types';

const createPartner = (body) => ({
  type: CREATE_PARTNER_REQUEST,
  payload: body,
});

const getPartnersForAdminTable = (offset, query) => ({
  type: GET_PARTNERS_FOR_TABLE_REQUEST,
  payload: offset,
  query,
});

const setPagePartnersAdminTable = (page) => ({
  type: SET_PAGE_PAGINATION,
  payload: page,
});

const getPartnerDataForUpdate = (id) => ({
  type: GET_PARTNER_DATA_REQUEST,
  payload: id,
});

const updatePartner = (id, body) => ({
  type: UPDATE_PARTNER_REQUEST,
  payload: { id, body },
});

const clearEdtiorAdminPartnersForm = () => ({
  type: CLEAR_EDITOR_FORM,
});

const addNewIP = (ip) => ({
  type: ADD_NEW_IP,
  payload: ip,
});

const delIp = (key) => ({
  type: DEL_IP,
  payload: key,
});

const changeAccess = (values) => ({
  type: CHANGE_ACCESS,
  payload: values,
});

export {
  clearEdtiorAdminPartnersForm,
  getPartnerDataForUpdate,
  updatePartner,
  setPagePartnersAdminTable,
  createPartner,
  getPartnersForAdminTable,
  addNewIP,
  delIp,
  changeAccess,
};
