import React from 'react';
import { PopupContainer } from 'components';
import { connect } from 'react-redux';
import { getUnitedFrontendParamsSelector, sharedSelectors } from 'redux/rootSelectors';
import { updateUnitedFrontSession, togglePopupUpdatingSessionUnitedFront, openWindowBlockUnitedFront } from 'redux/rootActions';
import { Button, Icon } from 'semantic-ui-react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';

const b = block('updating-united-session-frontend');
const {
  ATTENTION,
  IN_CURRENT_WINDOW,
  CLOSE_CURRENT_WINDOW,
  EXECUTION_NOT_FINISHED_IN_OTHER_WINDOW,
  WORK_CONTINUE,
} = LANG_DICTIONARY;

const propTypes = {
  isExistOtherUnitedFrontSession: PropTypes.bool.isRequired,
  updateSession: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  windowUuid: PropTypes.string.isRequired,
  togglePopupUpdatingSession: PropTypes.func.isRequired,
  riskProfile: PropTypes.string,
  masterId: PropTypes.string,
  qualification: PropTypes.number,
  openWindowUnitedFront: PropTypes.func.isRequired,
  unionServicePackageId: PropTypes.string,
};

const defaultProps = {
  masterId: null,
  riskProfile: null,
  qualification: null,
  unionServicePackageId: null,
};

const UpdateUnitedFrontendSession = ({
  isExistOtherUnitedFrontSession,
  updateSession,
  query,
  windowUuid,
  togglePopupUpdatingSession,
  riskProfile,
  masterId,
  qualification,
  openWindowUnitedFront,
  unionServicePackageId,
}) => {
  const handleUpdateSession = () => {
    updateSession({
      riskProfile: query.riskProfile || riskProfile,
      masterId: query.masterId || masterId,
      qualification: Number(query.qualification) || qualification,
      windowTimestamp: new Date().getTime(),
      windowUuid,
      unionServicePackageId: query.unionServicePackageId || unionServicePackageId,
    });

    togglePopupUpdatingSession(false);
  };

  const handleCloseSession = () => {
    togglePopupUpdatingSession(false);
    openWindowUnitedFront();
  };

  return (
    <PopupContainer
      isOpen={isExistOtherUnitedFrontSession}
      className={b()}
    >
      <div className={b('content')}>
        <div className={b('attention')}>
          <Icon name="warning sign" />
          {`${ATTENTION}!`}
        </div>
        <p className={b('description')}>{`${EXECUTION_NOT_FINISHED_IN_OTHER_WINDOW}?`}</p>
        <section className={b('button-wrapper')}>
          <Button className={b('button').toString()} onClick={handleUpdateSession}>
            <p className={b('btn-content')}>{WORK_CONTINUE}</p>
            <p className={b('btn-content')}>{IN_CURRENT_WINDOW.toLowerCase()}</p>
          </Button>
          <Button className={b('button').toString()} onClick={handleCloseSession}>
            <p className={b('btn-content')}>{CLOSE_CURRENT_WINDOW}</p>
          </Button>
        </section>
      </div>
    </PopupContainer>
  );
};

UpdateUnitedFrontendSession.propTypes = propTypes;
UpdateUnitedFrontendSession.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  ...getUnitedFrontendParamsSelector(state),
  ...sharedSelectors.getWindowUuid(state),
  query: sharedSelectors.getQueryParams(state),
});

const mapDispatchToProps = {
  updateSession: updateUnitedFrontSession,
  openWindowUnitedFront: openWindowBlockUnitedFront,
  togglePopupUpdatingSession: togglePopupUpdatingSessionUnitedFront,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUnitedFrontendSession);
