import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { connect } from 'react-redux';
import {
  Button, Form, Popup, Icon,
} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { withCustomRouter } from 'HOC';
import { Formik } from 'formik';
import _ from 'lodash';
import {
  checkSum,
  getProductForm,
  getProducts,
  setProductValues,
  getIszRestrictions,
  getCourseByRefExchangeRatesRegimesCoef,
  changeAgentFieldValue,
  downloadPresentation,
  downloadPresentationConstructor,
} from 'redux/rootActions';
import {
  LANG_DICTIONARY,
  REGEXP,
  ROUTES,
  NAMES_GROUP_SETTINGS,
  INS_LINE_TYPES,
} from 'consts';
import {
  CustomCheckBox,
  Hint,
  DateCustomPicker,
} from 'components';
import { SelectInput, TextInput } from 'components/_deprecated';
import {
  getErrorsFromValidationError,
  getFormattedDate,
  getProductConfig,
  getTerms,
  onlyNumbers,
  replaceAllButNumbers,
  isNumber,
  checkIsCoupon,
  checkIsKu,
} from 'helpers';
import { insLineIszCalc, setInitState } from 'redux/basketConstructor/actions';
import RiskProfileErrorPopup from 'components/_popups/RiskProfileError';
import PartitionCoefficient from 'components/_shared/PartitionCoefficient';
import schema from './schema';
import './styles.scss';
import { setAnnulContract, setEditBasket, checkPackageService } from '../../../redux/actions/checkoutActions';
import { actions, checkAccessAction } from '../../../roles';

const defaultConstructorPremiumThreshold = 1500000;

const roundingCurrencyRate = 7;

const iszScenarios = ['isz_1', 'isz_2', 'isz_3', 'isz_4', 'isz_5', 'isz_6'];

const defaultProps = {
  isLoading: false,
  signUp: null,
  saveFormValues: null,
  getProduct: null,
  CheckSum: null,
  history: null,
  productValues: null,
  productData: null,
  error: null,
  sumError: null,
  productForm: {},
  scenario: '',
  getAllProducts: () => null,
  products: [],
  mainRequestId: null,
  constructorProduct: false,
  insuredAgeError: null,
  childdAgeError: null,
  basicRisksList: [],
  additionalRisks: [],
  additionalRisksPackage: [],
  isLoadingDailyLimit: false,
  isLoadingProductForm: false,
  insLineIszObjects: [],
  getRestrictions: () => null,
  getCourseByCoef: () => null,
  isExchangeCoefFound: false,
  guaranteeLevel: null,
  calculatedSelected: null,
  regularSelected: null,
  readySelected: null,
  setInitStateBasket: () => null,
  basketConstructor: {},
  sentValue: () => null,
  childRequestIds: [],
  selected: {},
  isContractFormed: false,
  setAnnul: () => null,
  fund: {},
  id: null,
  setIsEditBasket: () => null,
  productId: null,
  downloadPresent: () => null,
  downloadPresentConstructor: () => null,
  isDownloadingPresent: false,
  roles: {},
  clientTestRecordFields: {},
  refUnionServicePackageId: null,
  CheckPackageService: () => null,
  needPackageService: false,
};

const propTypes = {
  insuredAgeError: PropTypes.string,
  isLoading: PropTypes.bool,
  signUp: PropTypes.func,
  saveFormValues: PropTypes.func,
  getProduct: PropTypes.func,
  CheckSum: PropTypes.func,
  history: PropTypes.object,
  productValues: PropTypes.object,
  productData: PropTypes.object,
  match: PropTypes.object.isRequired,
  error: PropTypes.string,
  sumError: PropTypes.string,
  productForm: PropTypes.object,
  scenario: PropTypes.string,
  getAllProducts: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.object),
  mainRequestId: PropTypes.number,
  constructorProduct: PropTypes.bool,
  childdAgeError: PropTypes.string,
  basicRisksList: PropTypes.arrayOf(PropTypes.string),
  additionalRisks: PropTypes.arrayOf(PropTypes.object),
  additionalRisksPackage: PropTypes.arrayOf(PropTypes.object),
  isLoadingDailyLimit: PropTypes.bool,
  isLoadingProductForm: PropTypes.bool,
  insLineIszObjects: PropTypes.arrayOf(PropTypes.object),
  getRestrictions: PropTypes.func,
  getCourseByCoef: PropTypes.func,
  isExchangeCoefFound: PropTypes.bool,
  guaranteeLevel: PropTypes.number,
  changeAgentValue: PropTypes.func.isRequired,
  calculatedSelected: PropTypes.object,
  regularSelected: PropTypes.object,
  readySelected: PropTypes.object,
  setInitStateBasket: PropTypes.func,
  basketConstructor: PropTypes.object,
  sentValue: PropTypes.func,
  childRequestIds: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.object,
  isContractFormed: PropTypes.bool,
  setAnnul: PropTypes.func,
  fund: PropTypes.object,
  id: PropTypes.number,
  setIsEditBasket: PropTypes.func,
  productId: PropTypes.number,
  downloadPresent: PropTypes.func,
  downloadPresentConstructor: PropTypes.func,
  isDownloadingPresent: PropTypes.bool,
  roles: PropTypes.object,
  clientTestRecordFields: PropTypes.object,
  refUnionServicePackageId: PropTypes.number,
  CheckPackageService: PropTypes.func,
  needPackageService: PropTypes.bool,
};

const b = block('preliminary');

const {
  NEXT,
  PRODUCT_PERIOD,
  PRODUCT_START,
  CONTRACT_CURRENCY,
  RATE,
  DECLARATION_CONFIRM,
  PRODUCT_VERSION,
  PERIOD_PAYMENT_FREQ,
  SIZE_OF_POLICY_AMOUNT,
  FINAL_INSURANCE_PREMIUM,
  RUB_SHORT,
  INSURANCE_AMOUNT_FOR_FINAL_LIFE,
  INSURANCE_AMOUNT_FINAL_SUM_ERROR,
  WARNING_FOR_CORRECTION_COEF,
  BIRTH_DATE,
  COUPON_MAIN_STRATEGY,
  PARTICIPATION_COEFFICIENT_MAIN_STRATEGY,
  COUPON_INFO,
  DESCRIPTION_DATE_INVEST,
  INVEST_DATE,
  UNABLE_CHECKOUT_PRODUCT,
  EDIT_BASKET,
  PRODUCT_PRESENTATION,
  DOWNLOAD_PRESENTATION_WARNING,
  RISK_PROFILE_ERROR_MESSAGE,
  SHARES_TOTAL,
} = LANG_DICTIONARY;

const { preliminary } = NAMES_GROUP_SETTINGS;

const { insuredData: insuredDataUrl } = ROUTES;

class PreliminaryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scenario: '',
      groupSettings: preliminary,
      isProductPeriod: false,
      isPolicySum: false,
      isCurrencyName: false,
      isRate: false,
      isProductStart: false,
      isPremium: false,
      isCorrectionCoefError: false,
      isCoefErrorShow: true,
    };
  }

  componentDidMount() {
    const {
      getProduct,
      match: { params: { id } },
      getAllProducts,
      mainRequestId,
      constructorProduct,
      history,
      guaranteeLevel,
      productValues,
    } = this.props;

    getAllProducts(id);
    getProduct(
      id,
      constructorProduct,
      mainRequestId,
      history,
      guaranteeLevel,
    );
    this.setState({
      isCoefErrorShow:
      productValues.coefs ?
        productValues.coefs.reduce((acc, el) => acc + Number(el || 0), 0) !== 100 :
        true,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { scenario } = nextProps;
    const { scenario: oldScenario, groupSettings } = prevState;
    if (scenario !== oldScenario) {
      const productConfig = getProductConfig(scenario, groupSettings);

      return { scenario, ...productConfig };
    }

    return null;
  }

  checkIszScenario() {
    const { scenario } = this.props;

    return iszScenarios.includes(scenario);
  }

  getPremium = (values) => {
    const {
      productForm: {
        productTerms,
        productsPremiumFreq,
        insPremiumFreq,
        currencyRate,
      },
    } = this.props;
    const { isRate } = this.state;

    const {
      policySum,
      premiumFreqId,
      productPeriod,
    } = values;

    const term = productPeriod
      ? productTerms.find((item) => item.termId === productPeriod)
      : null;
    const amountLimit = insPremiumFreq?.[0]
      ? insPremiumFreq[0].paymentAmountLimit
      : null;
    const valueNumber = productsPremiumFreq[0]
      ? productsPremiumFreq[0].valueNumber
      : null;
    const numberOfInsuranceFee = valueNumber
      ? this.getPaymentsCount(valueNumber, term)
      : this.getNumberOfInsuranceFee(premiumFreqId, term);
    const finalPremium = (amountLimit && amountLimit < numberOfInsuranceFee)
      ? (policySum * amountLimit)
      : (policySum * numberOfInsuranceFee);
    const premium = isRate
      ? (isNumber(finalPremium) * parseFloat(currencyRate?.toFixed(roundingCurrencyRate)))
      : isNumber(finalPremium);

    return premium;
  }

  closeRiskProfileError = () => {
    this.setState({ showRiskProfileError: false });
  }

  nextStep = (values) => {
    const {
      match: { params: { id: partnersInsProductId } },
      productForm: {
        isCurrencyScenario,
        productSelection,
      },
      needPackageService,
      CheckPackageService,
    } = this.props;

    if (!productSelection.status) {
      this.setState({ showRiskProfileError: true });

      return;
    }

    const { policySum } = values;

    isCurrencyScenario && this.calculateCourseByContractAmount(
      policySum,
    );

    const {
      changeAgentValue,
      clientTestRecordFields,
    } = this.props;

    changeAgentValue({
      name: 'firstName',
      value: clientTestRecordFields.firstName,
      blockName: 'personBlock',
      form: 'insurer',
    });

    changeAgentValue({
      name: 'lastName',
      value: clientTestRecordFields.lastName,
      blockName: 'personBlock',
      form: 'insurer',
    });

    changeAgentValue({
      name: 'patronymic',
      value: clientTestRecordFields.middleName,
      blockName: 'personBlock',
      form: 'insurer',
    });

    changeAgentValue({
      name: 'mobilePhone',
      value: clientTestRecordFields.mobileNumber,
      blockName: 'contactBlock',
      form: 'insurer',
    });

    if (needPackageService) {
      CheckPackageService({ partnersInsProductId, callback: this.checkAndSaveFormValues, values });

      return;
    }
    this.checkAndSaveFormValues(values);
  };

  checkAndSaveFormValues = (values) => {
    const {
      history,
      saveFormValues,
      match: { params: { id: partnersInsProductId } },
      productForm: {
        regDate,
        currencyRateId,
        productSelection,
      },
      basketConstructor,
      sentValue,
      mainRequestId,
      childRequestIds,
      selected,
    } = this.props;

    if (!productSelection.status) {
      this.setState({ showRiskProfileError: true });

      return;
    }
    const rateId = { currencyRateId } || null;
    const premium = this.getPremium(values);
    this.checkProductSum(values, () => {
      saveFormValues({
        ...values,
        ...rateId,
        insuranceAmmount: premium,
        regDate,
      });
      history.push(`${insuredDataUrl}/${partnersInsProductId}`);
    });

    if (this.checkIsIszConstructor()) {
      sentValue({
        mainInsLineIszCalcRequestId: selected.id,
        childInsLineIszCalcRequestIds: selected.child.map(({ id }) => id),
        guaranteeLevel: basketConstructor.guaranteeLevelPolicy,
        refIszOptionTypeCode: basketConstructor.productOptionCodePolicy,
        refCurrencyCode: selected.refCurrencyCode,
        refProductTypeCode: basketConstructor.productTypeCodePolicy.toString(),
        isOnlyFavorite: basketConstructor.isOnlyFavorite,
        partnersInsProductId,
        insuranceAmount: premium,
        mainRequestId,
        childRequestIds,
      });
    }
  };

  handleClick = () => {
    const {
      history,
      isContractFormed,
      setAnnul,
      setIsEditBasket,
      productId,
    } = this.props;

    setIsEditBasket(true);
    history.push({ pathname: ROUTES.constructorIsz({ id: productId }) });
    isContractFormed && setAnnul(true);
  }

  validateForm = (values) => {
    const { productForm } = this.props;
    const { productPeriod, productVersion } = values;

    const ageRestrictions = productForm.restrictions.insured.find(
      (item) => item.insLineIszObjectId === productVersion,
    );

    const isIszScenario = this.checkIszScenario();

    try {
      schema(
        productForm,
        values.productVersion,
        productPeriod,
        ageRestrictions,
        isIszScenario,
        values.birthDate,
      )
        .validateSync({ ...values }, { abortEarly: false });

      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };

  checkProductSum = (values, cbSuccess) => {
    const {
      CheckSum,
      match: { params: { id } },
      mainRequestId,
      constructorProduct,
    } = this.props;
    const sumInsured = replaceAllButNumbers(String(values.policySum));
    if (sumInsured.length < 15) {
      CheckSum({
        id,
        sumInsured,
        constructorProduct,
        mainRequestId,
        cbSuccess,
        premiumFreq: values.premiumFreqId,
      });
    }
  };

  setRestrictionsError = (setErrors, errors) => (error) => {
    setErrors({
      ...errors,
      premiumFreqId: error,
      policySum: null,
    });
  }

  getProductRestrictions = ({ target: { value } }, errorCallback) => {
    const {
      getRestrictions,
      match: { params: { id } },
      mainRequestId,
    } = this.props;
    getRestrictions(value, id, errorCallback, mainRequestId);
  }

  getPaymentsCount = (valueNumber, productTerm) => {
    const term = _.get(productTerm, 'value', 0);
    const paymentsCount = (term * 12) / valueNumber;

    return Math.round(paymentsCount);
  }

  getNumberOfInsuranceFee = (premiumFreqId, productTerm) => {
    const term = _.get(productTerm, 'value', 0);
    const PREMIUM_FREQ_MAPPER = {
      7: () => 1,
      5: (T) => T,
      4: (T) => 2 * T,
      3: (T) => 4 * T,
      2: (T) => 12 * T,
      12: (T) => 24 * T,
    };

    const mapperAction = PREMIUM_FREQ_MAPPER[String(premiumFreqId)];

    if (!mapperAction) {
      return 0;
    }

    return mapperAction(term);
  }

  findCorrectCoefIdInListOfCoefsByContractAmount(coefs, amount) {
    const correctCoefs = coefs
      .filter((coef) => this.checkIsAmountSatisfiesTheConstraints(coef, amount));

    if (correctCoefs.length > 1) {
      return this.findNearestPremiumAmountCoefIdInInterval(coefs);
    }

    return correctCoefs[0] ? correctCoefs[0].id : null;
  }

  checkIsAmountSatisfiesTheConstraints =
    (coef, amount) => (coef.premiumAmountMin <= amount && coef.premiumAmountMax >= amount);

  findNearestPremiumAmountCoefIdInInterval = (coefs) => {
    const correctCoef = coefs
      .map((coef) => ({
        value: coef.premiumAmountMax - coef.premiumAmountMin,
        id: coef.id,
      }))
      .sort((el1, el2) => el1.value - el2.value)[0];

    return correctCoef.id;
  }

  calculateCourseByContractAmount = (amount) => {
    const {
      productForm: {
        refExchangeRatesRegimesCoefs,
      },
      getCourseByCoef,
    } = this.props;

    const findedCorrectCoef = this.findCorrectCoefIdInListOfCoefsByContractAmount(
      refExchangeRatesRegimesCoefs,
      amount,
    );

    if (findedCorrectCoef) {
      this.setState({ isCorrectionCoefError: false });

      return getCourseByCoef(findedCorrectCoef);
    }

    this.setState({ isCorrectionCoefError: true });

    return null;
  }

  onChangeBirthDate = (date) => {
    const {
      changeAgentValue,
      productForm: {
        insurerIsInsured,
      },
    } = this.props;

    changeAgentValue({
      name: 'birthDate',
      value: date,
      blockName: 'personBlock',
      form: 'insured',
    });

    if (insurerIsInsured) {
      changeAgentValue({
        name: 'birthDate',
        value: date,
        blockName: 'personBlock',
        form: 'insurer',
      });
    }
  }

  checkInsurancePremium = (values) => {
    const {
      selected,
    } = this.props;

    const premium = this.getPremium(values);

    if (premium === '' || premium === 0) {
      return '';
    }
    if (premium >= defaultConstructorPremiumThreshold) {
      return selected.yield2;
    }

    return selected.yield1;
  }

  checkIsIszConstructor() {
    const { scenario } = this.props;
    if (scenario === 'isz_5' ||
        scenario === 'isz_6') {
      return true;
    }

    return false;
  }

  calculatePremium = (values) => {
    const {
      isRate,
    } = this.state;

    const {
      productForm,
    } = this.props;

    const term = values.productPeriod
      ? productForm.productTerms.find((item) => item.termId === values.productPeriod)
      : null;

    const amountLimit = productForm.insPremiumFreq?.[0]
      ? productForm.insPremiumFreq[0].paymentAmountLimit
      : null;
    const valueNumber = productForm.productsPremiumFreq[0]
      ? productForm.productsPremiumFreq[0].valueNumber
      : null;
    const numberOfInsuranceFee = valueNumber
      ? this.getPaymentsCount(valueNumber, term)
      : this.getNumberOfInsuranceFee(values.premiumFreqId, term);
    const finalPremium = (amountLimit && amountLimit < numberOfInsuranceFee)
      ? (values.policySum * amountLimit)
      : (values.policySum * numberOfInsuranceFee);
    const premium = isRate
      ? Math.round((
        isNumber(finalPremium)
              * parseFloat(productForm.currencyRate?.toFixed(roundingCurrencyRate)) * 100)) / 100
      : isNumber(finalPremium);

    return premium;
  }

  handleSavePresentation = (values, term) => {
    const {
      downloadPresent,
      downloadPresentConstructor,
      match: { params: { id } },
      mainRequestId,
      productForm,
      childRequestIds,
      guaranteeLevel,
    } = this.props;

    const premium = this.calculatePremium(values);

    this.checkIsIszConstructor() ?
      downloadPresentConstructor({
        id,
        isConstructor: true,
        policySum: values.policySum,
        productPremiumFreqId: values.premiumFreqId,
        mainRequestId,
        childRequestIds,
        insLineIszObjectId: values.productVersion,
        guaranteeLevel,
        productName: productForm.nameShort,
        productTerm: term.value,
        productTermUnit: term.unit,
        productCur: productForm.currencyName,
        premium,
      }) :
      downloadPresent({
        id,
        isConstructor: false,
        policySum: values.policySum,
        productTermId: values.productPeriod,
        productPremiumFreqId: values.premiumFreqId,
        insLineIszObjectId: values.productVersion,
        productName: productForm.nameShort,
        productTerm: term.value,
        productTermUnit: term.unit,
        productCur: productForm.currencyName,
        premium,
      });
  }

  render() {
    const {
      isProductPeriod,
      isPolicySum,
      isCurrencyName,
      isRate,
      isProductStart,
      isPremium,
      isCorrectionCoefError,
      showRiskProfileError,
      isCoefErrorShow,
    } = this.state;

    const {
      isLoading,
      error: serverError,
      productValues,
      productForm,
      roles,
      productForm: {
        currencyName,
        productTip,
        productTerms,
        regDate,
        dateInvest,
        currencyRate,
        scenario,
        declarationAcceptance,
        productSelection,
        productsPremiumFreq,
        insPremiumFreq,
        iszRestrictions,
        isCurrencyScenario,
        hasPresentation,
      },
      constructorProduct: isConstructorProduct,
      isLoadingDailyLimit,
      isLoadingProductForm,
      insLineIszObjects,
      isExchangeCoefFound,
      guaranteeLevel,
      selected,
      isDownloadingPresent,
    } = this.props;

    if (!scenario) {
      return null;
    }

    if (!scenario.includes(INS_LINE_TYPES.ISZ)) {
      return <Redirect to={ROUTES.notFound} />;
    }

    const productsPremium = productsPremiumFreq.map((item) => ({
      ...item,
      key: item.id,
    }));

    const isOnePremiumFreq = productsPremiumFreq.length === 1;

    return (
      <div className={b('form')}>
        <RiskProfileErrorPopup
          message={showRiskProfileError && RISK_PROFILE_ERROR_MESSAGE}
          onClose={this.closeRiskProfileError}
        />
        <Formik
          initialValues={productValues}
          validate={this.validateForm}
          onSubmit={this.nextStep}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldTouched,
            setValues,
            setErrors,
          }) => {
            const inputProps = {
              values,
              errors,
              touched,
              onChange: handleChange,
              onBlur: handleBlur,
              setFieldTouched,
            };

            const {
              policySum,
              premiumFreqId,
              productPeriod,
              productVersion,
            } = values;

            const term = productPeriod
              ? productTerms.find((item) => item.termId === productPeriod)
              : null;

            const amountLimit = insPremiumFreq?.[0]
              ? insPremiumFreq[0].paymentAmountLimit
              : null;
            const valueNumber = productsPremiumFreq[0]
              ? productsPremiumFreq[0].valueNumber
              : null;
            const numberOfInsuranceFee = valueNumber
              ? this.getPaymentsCount(valueNumber, term)
              : this.getNumberOfInsuranceFee(premiumFreqId, term);
            const finalPremium = (amountLimit && amountLimit < numberOfInsuranceFee)
              ? (policySum * amountLimit)
              : (policySum * numberOfInsuranceFee);
            const isInsurancePremium = this.checkInsurancePremium(values);

            const isCoupon = checkIsCoupon(selected);

            const isKu = checkIsKu(selected);

            const premium = isRate
              ? Math.round((
                isNumber(finalPremium)
              * parseFloat(currencyRate?.toFixed(roundingCurrencyRate)) * 100)) / 100
              : isNumber(finalPremium);

            const isDisabledFieldDueToVersionProduct = insLineIszObjects.length &&
              !values.productVersion;

            const isDisableDateDueToVersionOrPeriod = isDisabledFieldDueToVersionProduct ||
              !values.productPeriod;

            const isDisabledByNoRestrictions = !iszRestrictions.length;

            const isDisablePolicyAmount = isDisabledFieldDueToVersionProduct
            || isDisabledByNoRestrictions;

            const insObjectRisks = _.get(productForm, 'insObjectRisk', []);
            const insObjectRisk = insLineIszObjects.length
              ? insObjectRisks
                .find(({ insLineIszObjectId }) => insLineIszObjectId === productVersion)
                || { insSumPercent: 0 }
              : insObjectRisks[0];
            const {
              insSumCurMax,
              insSumCurMin,
              insSumPercent,
            } = insObjectRisk;
            const insuranceSumOnFinalLife =
              Number(((finalPremium * insSumPercent) / 100).toFixed(2));

            const survivorshipInsuranceAmountAndCapitalProtection =
              Number(((finalPremium * guaranteeLevel) / 100).toFixed(2));
            const isValidInsuranceSumOnFinalLife = insSumCurMin <= insuranceSumOnFinalLife
              && insSumCurMax >= insuranceSumOnFinalLife;
            const isShowFinalInsuranceSumError = !isValidInsuranceSumOnFinalLife
              && insuranceSumOnFinalLife > 0;

            const paramsForFront = term ?
              productForm.paramsForFront.filter((item) => item.term === term.value) : [];

            const isCoefsOk = paramsForFront.length ? !isCoefErrorShow &&
              (values.coefErrors ? Object.values(values.coefErrors)
                .filter((status) => status).length === 0 : false) : true;

            const isDisabledNextBtn = (isLoadingDailyLimit || isLoadingProductForm) ||
              (!values.isDeclarationAcceptance && declarationAcceptance) ||
                isShowFinalInsuranceSumError ||
                isCorrectionCoefError || !values.policySum ||
                this.checkInsurancePremium(values) < 0 ||
                Number.isNaN(Date.parse(values.birthDate)) || !isCoefsOk;

            const restriction = insLineIszObjects.length ?
              iszRestrictions.find(
                ({ insLineIszObjectId }) => insLineIszObjectId === values.productVersion,
              ) :
              iszRestrictions[0];
            const isNextAction = checkAccessAction(
              actions.SHOW_PRELIMINARY_CALCULATION_NEXT, roles,
            );

            const isPresentationDisabled = !values.productPeriod ||
                !values.premiumFreqId || !values.productVersion
                || values.policySum < restriction?.minValue;

            const partitionCoefs = paramsForFront.length > 0 && ['isz_1', 'isz_2', 'isz_3', 'isz_4'].includes(scenario);

            return (
              <Form onSubmit={handleSubmit} loading={isLoading}>
                <div className="formCont">
                  <div className={b('form', 'inputs')}>
                    {
                      Boolean(insLineIszObjects.length) && (
                        <SelectInput
                          name="productVersion"
                          label={PRODUCT_VERSION}
                          placeholder={PRODUCT_VERSION}
                          options={insLineIszObjects}
                          {...inputProps}
                        />
                      )
                    }
                    {
                      isProductPeriod && (
                        <SelectInput
                          name="productPeriod"
                          label={PRODUCT_PERIOD}
                          placeholder={PRODUCT_PERIOD}
                          options={getTerms(productTerms)}
                          disabled={isConstructorProduct || isDisabledFieldDueToVersionProduct}
                          {...inputProps}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              coefs: [],
                              insLineIszParamsFront: [],
                            });
                            this.setState({
                              isCoefErrorShow: true,
                            });
                            inputProps.onChange(e);
                          }}
                        />
                      )
                    }
                    <SelectInput
                      name="premiumFreqId"
                      label={PERIOD_PAYMENT_FREQ}
                      placeholder={PERIOD_PAYMENT_FREQ}
                      options={productsPremium}
                      {...inputProps}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          policySum: '',
                        });
                        setFieldTouched('premiumFreqId', true);
                        const errorCallback = this.setRestrictionsError(setErrors, errors);
                        this.getProductRestrictions(e, errorCallback);
                        inputProps.onChange(e);
                      }}
                      disabled={isOnePremiumFreq}
                    />
                    {
                      isPolicySum && (
                        <TextInput
                          name="policySum"
                          type="text"
                          positionMessageError="right"
                          label={`${SIZE_OF_POLICY_AMOUNT}, ${currencyName}`}
                          placeholder={`${SIZE_OF_POLICY_AMOUNT}, ${currencyName}`}
                          disabled={isDisablePolicyAmount}
                          {...inputProps}
                          onChange={({ target: { value, name } }) => {
                            const contractAmount = Number(onlyNumbers(value));
                            handleChange({
                              target: {
                                value: contractAmount,
                                name,
                              },
                            });
                            isCurrencyScenario && this.calculateCourseByContractAmount(
                              contractAmount,
                            );
                          }}
                          values={{
                            ...values,
                            policySum: String(values.policySum || '').replace(REGEXP.SEPARATOR_THOUSANDS, '$1 '),
                          }}
                        />
                      )
                    }
                    {
                      isCurrencyName && (
                      <div className={b('form', 'total')}>
                        <div className={b('form', 'total', 'label')}>{ CONTRACT_CURRENCY }</div>
                        <div className={b('form', 'total', 'value')}>{ currencyName }</div>
                      </div>
                      )
                    }
                    {
                      this.checkIsIszConstructor() && isCoupon && (
                        <div className={b('form', 'total')}>
                          <div className={b('form', 'total', 'label')}>{ COUPON_MAIN_STRATEGY }</div>
                          <div className={b('form', 'total', 'value')}>{ isInsurancePremium }</div>
                          <div className={b('info')}>
                            <Popup
                              content={COUPON_INFO}
                              trigger={<Icon className={b('icon')} size="large" name="info circle" />}
                              basic
                            />
                          </div>
                        </div>
                      )
                    }
                    {
                      this.checkIsIszConstructor() && isKu && (
                      <div className={b('form', 'total')}>
                        <div className={b('form', 'total', 'label')}>{ PARTICIPATION_COEFFICIENT_MAIN_STRATEGY }</div>
                        <div className={b('form', 'total', 'value')}>{ isInsurancePremium }</div>
                        <div className={b('info')}>
                          <Popup
                            content={COUPON_INFO}
                            trigger={<Icon className={b('icon')} size="large" icon="info circle" />}
                            basic
                          />
                        </div>
                      </div>
                      )
                    }
                    {
                      isRate && (
                        <div className={b('form', 'total')}>
                          <div className={b('form', 'total', 'label')}>{ RATE }</div>
                          <div className={b('form', 'total', 'value')}>{ isExchangeCoefFound ? currencyRate.toFixed(roundingCurrencyRate) : '-' }</div>
                        </div>
                      )
                    }
                    {
                      isProductStart && (
                        <div className={b('form', 'total')}>
                          <div className={b('form', 'total', 'label')}>{ PRODUCT_START }</div>
                          <div className={b('form', 'total', 'value')}>{ getFormattedDate(regDate) }</div>
                        </div>
                      )
                    }
                    {
                      isProductStart && (
                        <div className={b('form', 'total')}>
                          <div className={b('form', 'total', 'label')}>{ INVEST_DATE }</div>
                          <div className={b('form', 'total', 'value')}>{ getFormattedDate(dateInvest) }</div>
                          <div className={b('info')}>
                            <Popup
                              content={DESCRIPTION_DATE_INVEST}
                              trigger={<Icon className={b('icon')} size="large" name="info circle" />}
                              basic
                            />
                          </div>
                        </div>
                      )
                    }
                    {
                      isPremium && (
                        <div className={b('form', 'total')}>
                          <div className={b('form', 'total', 'label')}>
                            { FINAL_INSURANCE_PREMIUM }
                          </div>
                          <div className={b('form', 'total', 'value')}>
                            { `${String(premium).replace(REGEXP.SEPARATOR_THOUSANDS, '$1 ')} ${RUB_SHORT}` }
                          </div>
                        </div>
                      )
                    }
                    <div className={b('form', 'total')}>
                      <div className={b('form', 'total', 'label', { error: isShowFinalInsuranceSumError })}>
                        { INSURANCE_AMOUNT_FOR_FINAL_LIFE }
                      </div>
                      <div className={b('form', 'total', 'value', { error: isShowFinalInsuranceSumError })}>
                        { `${String(isConstructorProduct ? survivorshipInsuranceAmountAndCapitalProtection : insuranceSumOnFinalLife).replace(REGEXP.SEPARATOR_THOUSANDS, '$1 ')} ${currencyName}` }
                      </div>
                    </div>
                    {isShowFinalInsuranceSumError && <p className={b('final-amount-error')}>{INSURANCE_AMOUNT_FINAL_SUM_ERROR(insSumCurMin, insSumCurMax)}</p>}
                    {isCorrectionCoefError && <p className={b('final-amount-error')}>{WARNING_FOR_CORRECTION_COEF}</p>}
                    <DateCustomPicker
                      label={BIRTH_DATE}
                      placeholder={BIRTH_DATE}
                      name="birthDate"
                      maxDate={new Date()}
                      values={values}
                      disabled={isDisableDateDueToVersionOrPeriod}
                      {...inputProps}
                      onChange={({ target: { value, name } }) => {
                        this.checkInsurancePremium(values);
                        this.onChangeBirthDate(value);
                        handleChange({
                          target: {
                            value,
                            name,
                          },
                        });
                      }}
                    />
                    {declarationAcceptance && (
                    <div className={b('form', 'total')}>
                      <div className={b('form', 'total', 'label')}>{ DECLARATION_CONFIRM }</div>
                      <div className={b('form', 'total', 'value')}>
                        <CustomCheckBox
                          name="isDeclarationAcceptance"
                          {...inputProps}
                          onChange={(name, checked) => {
                            handleChange({
                              target: {
                                name,
                                value: !checked,
                              },
                            });
                          }}
                          checked={values.isDeclarationAcceptance}
                        />
                      </div>
                    </div>
                    )}
                    {partitionCoefs && (
                    <PartitionCoefficient
                      values={values.coefs}
                      data={paramsForFront}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          coefs: value,
                          insLineIszParamsFront: paramsForFront.map((item, index) => (
                            {
                              insLineIszParamId:
                                item.insLineIszParamId,
                              frontPartitionCoef: Number(value[index] || 0),
                            })),
                        });
                        this.setState({
                          isCoefErrorShow:
                          value.reduce((acc, el) => acc + Number(el || 0), 0) !== 100,
                        });
                      }}
                      onError={({ index: indexError, status }) => {
                        setValues({
                          ...values,
                          coefErrors: { ...values.coefErrors, [indexError]: status },
                        });
                      }}
                      errors={values.coefErrors}
                      showError={isCoefErrorShow}
                      generalError={SHARES_TOTAL}
                    />
                    )}
                    { serverError && <div className={b('error')}>{ serverError }</div> }
                  </div>
                  <Hint tipText={productTip} />
                </div>
                <div className={b('form', 'interface')}>
                  {this.checkIsIszConstructor() && (
                  <div className={b('edit-basket')}>
                    <Button
                      type="button"
                      className="preliminary__form__nextButton"
                      onClick={this.handleClick}
                    >
                      {EDIT_BASKET}
                    </Button>
                  </div>
                  )}
                  {
                    hasPresentation && (
                    <div className={b('product-presentation')}>
                      <Popup
                        content={DOWNLOAD_PRESENTATION_WARNING}
                        basic
                        disabled={!isPresentationDisabled}
                        trigger={(
                          <div>
                            <Button
                              type="button"
                              className="preliminary__form__nextButton"
                              disabled={isPresentationDisabled || isDownloadingPresent}
                              onClick={() => { this.handleSavePresentation(values, term); }}
                            >
                              {PRODUCT_PRESENTATION}
                            </Button>
                          </div>
                        )}
                      />
                    </div>
                    )
                  }
                  <Popup
                    content={UNABLE_CHECKOUT_PRODUCT}
                    basic
                    disabled={this.checkIsIszConstructor()
                      ? isInsurancePremium >= 0 : productSelection.status}
                    trigger={isNextAction ? (
                      <div>
                        <Button
                          type="submit"
                          className="preliminary__form__nextButton"
                          disabled={isDisabledNextBtn}
                        >
                          {NEXT}
                        </Button>
                      </div>
                    ) : (<div />)}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

PreliminaryForm.propTypes = propTypes;
PreliminaryForm.defaultProps = defaultProps;

function mapStateToProps({
  checkoutReducer: {
    productValues,
    productForm,
    productForm: {
      needPackageService,
    },
    sumError,
    scenario,
    products,
    mainRequestId,
    constructorProduct,
    isLoadingDailyLimit,
    isLoadingProductForm,
    isSuccessCheckSum,
    insLineIszObjects,
    isExchangeCoefFound,
    guaranteeLevel,
    childRequestIds,
    isContractFormed,
    fund,
    isEditBasket,
    productId,
    isDownloadingPresent,
    refUnionServicePackageId,
  },
  authReducer: {
    roles,
    clientTestRecordFields,
  },
  basketConstructor,
  basketConstructor: {
    selected,
    calculatedPersonBaskets: {
      selected: calculatedSelected,
    },
    readyBaskets: {
      selected: readySelected,
    },
    regularBaskets: {
      selected: regularSelected,
    },
  },

}) {
  return {
    productValues,
    productForm,
    sumError,
    scenario,
    roles,
    products,
    mainRequestId,
    constructorProduct,
    isLoadingDailyLimit,
    isLoadingProductForm,
    isSuccessCheckSum,
    insLineIszObjects,
    isExchangeCoefFound,
    guaranteeLevel,
    calculatedSelected,
    readySelected,
    regularSelected,
    basketConstructor,
    childRequestIds,
    selected,
    isContractFormed,
    fund,
    isEditBasket,
    productId,
    isDownloadingPresent,
    clientTestRecordFields,
    needPackageService,
    refUnionServicePackageId,
  };
}

export default withCustomRouter(connect(
  mapStateToProps,
  {
    getAllProducts: getProducts,
    saveFormValues: setProductValues,
    getProduct: getProductForm,
    CheckSum: checkSum,
    CheckPackageService: checkPackageService,
    getRestrictions: getIszRestrictions,
    getCourseByCoef: getCourseByRefExchangeRatesRegimesCoef,
    changeAgentValue: changeAgentFieldValue,
    setInitStateBasket: setInitState,
    sentValue: insLineIszCalc,
    setAnnul: setAnnulContract,
    setIsEditBasket: setEditBasket,
    downloadPresent: downloadPresentation,
    downloadPresentConstructor: downloadPresentationConstructor,
  },
)(PreliminaryForm));
