import {
  SET_PRODUCT_VALUES,
  SET_INSURER_VALUES,
  SET_INSURED_VALUES,
  SET_INSURED_IS_SAME,
  CHANGE_BENEFICIARY_COUNT,
  GET_PRODUCTS_REQUEST,
  CHECK_PRODUCT_REQUEST,
  GET_PRODUCT_FORM_REQUEST,
  CHECK_SUM_REQUEST,
  CHECK_PACKAGE_SERVICE_REQUEST,
  SET_BENEFICIARY_VALUES,
  SAVE_FORMS_REQUEST,
  RESET_FORMS,
  RESET_ERRORS,
  GET_DATA_CONTRACT_REQUEST,
  SET_ANNUL_STATUS,
  SAVE_SCAN_REQUEST,
  GET_CONTRACT_FILE_REQUEST,
  START_VALIDATE_TABS,
  CLEAR_VALIDATE_TABS,
  SET_FUND_DESCRIPTION,
  GET_REGENERATE_CONTRACT_FILE_REQUEST,
  GET_CONTRACT_COPY_REQUEST,
  CANCEL_CURRENT_JOB_REQUEST,
  GET_AUTO_IMPORT_JOBS_REQUEST,
  START_AUTO_IMPORT_REQUEST,
  PARSE_XML_FILE_FOR_SELECTS_REQUEST,
  CLOSE_IMPORT_FILE_FROM1C_POPUP,
  CHANGE_AUTO_IMPORT_SETTINGS,
  GET_BUILD_HASH_VERSION_REQUEST,
  GET_ADDITIONAL_DOCS_REQUEST,
  DOWNLOAD_ADDITIONAL_FILE_REQUEST,
  CHECK_ACCOUNT_NUMBERS_REQUEST,
  SET_INIT_DATA,
  FILL_INSURER_DATA_CHECKOUT,
  FILL_INSURED_DATA_ISZ,
  FILL_BENEFECIARES_DATA_ISZ,
  GET_ISZ_RESTIRCTIONS,
  GET_CURRENT_COURSE,
  SET_ANNUL_CONTRACT,
  SET_CONTRACT_FORMED,
  RESET_CTS_IDS,
  SET_EDIT_BASKET,
  SET_CTS_ID_ARR,
  DOWNLOAD_PRESENTATION,
  GET_SIGNING_TYPE,
  SET_SIGNING_TYPE,
  SET_IS_SCAN_UPLOAD_BY_CLIENT,
  CHECK_IS_ACTIVE_CONFIRM_BUTTON,
  SET_INIT_STATE_SIGNING_TYPE,
  SET_SMS_SENT,
  SENT_SMS,
  CLIENT_TEST_CHECK_NEED_TESTING_REQUEST,
  CLIENT_TEST_CHECK_TEST_STATUS_REQUEST,
  CLIENT_TEST_CREATE_RECORD_REQUEST,
  CLIENT_TEST_UPDATE_CREATE_RECORD_REQUEST,
  CLIENT_TEST_GET_QUESTIONS_REQUEST,
  CLIENT_TEST_SET_PARAMS,
  CLIENT_TEST_UPDATE_TEST_STATUS_REQUEST,
  CLIENT_TEST_CHECK_TEST_LINK_REQUEST,
  CLIENT_TEST_CHECK_USER_NOTBCS_REQUEST,
  CLIENT_TEST_GET_RECORD_FIELDS_REQUEST,
  CLIENT_TEST_SET_RECORD_FIELDS,
} from 'redux/reducers/types';

const downloadPresentation = (
  id,
  isConstructor,
  policySum,
  productTermId,
  productPremiumFreqId,
  insLineIszObjectId,
  productName,
  productTerm,
  productTermUnit,
  productCur,
  premium,
) => ({
  type: DOWNLOAD_PRESENTATION.request,
  payload: {
    id,
    isConstructor,
    policySum,
    productTermId,
    productPremiumFreqId,
    insLineIszObjectId,
    productName,
    productTerm,
    productTermUnit,
    productCur,
    premium,
  },
});

const downloadPresentationConstructor = ({
  id,
  isConstructor,
  policySum,
  productPremiumFreqId,
  mainRequestId,
  childRequestIds,
  insLineIszObjectId,
  guaranteeLevel,
  productName,
  productTermId,
  productTermUnit,
  productCur,
  productTerm,
  premium,
}) => ({
  type: DOWNLOAD_PRESENTATION.request,
  payload: {
    id,
    isConstructor,
    policySum,
    productTermId,
    productPremiumFreqId,
    mainRequestId,
    childRequestIds,
    insLineIszObjectId,
    guaranteeLevel,
    productName,
    productTermUnit,
    productCur,
    productTerm,
    premium,
  },
});

const setCtsIdArr = (arr) => ({
  type: SET_CTS_ID_ARR,
  payload: {
    arr,
  },
});

const getIszRestrictions = (premiumFreq, productId, errorCallback, mainRequestId) => ({
  type: GET_ISZ_RESTIRCTIONS.request,
  payload: {
    premiumFreq,
    productId,
    errorCallback,
    mainRequestId,
  },
});

const setEditBasket = (isEditBasket) => ({
  type: SET_EDIT_BASKET,
  payload: { isEditBasket },
});

const setAnnulContract = (isAnnul) => ({
  type: SET_ANNUL_CONTRACT,
  payload: { isAnnul },
});

const setContractFormed = (isContractFormed) => ({
  type: SET_CONTRACT_FORMED,
  payload: { isContractFormed },
});

const getCourseByRefExchangeRatesRegimesCoef = (coefId) => ({
  type: GET_CURRENT_COURSE.request,
  payload: { coefId },
});

const fillBenefeciaryDataIsz = (beneficiaries, beneficiaryInLaw) => ({
  type: FILL_BENEFECIARES_DATA_ISZ,
  payload: {
    beneficiaries,
    beneficiaryInLaw,
  },
});

const fillInsuredDataIsz = (insured) => ({
  type: FILL_INSURED_DATA_ISZ,
  payload: insured,
});

const fillInsurerDataCheckout = (insurer) => ({
  type: FILL_INSURER_DATA_CHECKOUT,
  payload: insurer,
});

const checkAccountNumbers = (num) => ({
  type: CHECK_ACCOUNT_NUMBERS_REQUEST,
  payload: num,
});

const changeAutoImportSetting = (name, value) => ({
  type: CHANGE_AUTO_IMPORT_SETTINGS,
  payload: { name, value },
});

const closePopupImportFileFrom1C = () => ({
  type: CLOSE_IMPORT_FILE_FROM1C_POPUP,
});

const parseXmlFile = (file) => ({
  type: PARSE_XML_FILE_FOR_SELECTS_REQUEST,
  payload: file,
});

const startAutoImport = (
  {
    errorFilesDir,
    newFilesDir,
    successFilesDir,
    time,
    interval,
    taskType,
    priorityId,
    action,
    cronTypeId,
  },
) => ({
  type: START_AUTO_IMPORT_REQUEST,
  payload: {
    cronTypeId,
    errorFilesDir,
    newFilesDir,
    successFilesDir,
    time,
    interval,
    taskType,
    priorityId,
    action,
  },
});

const getImportJobs = () => ({
  type: GET_AUTO_IMPORT_JOBS_REQUEST,
});

const cancelCurrentJob = (taskType, action) => ({
  type: CANCEL_CURRENT_JOB_REQUEST,
  payload: { taskType, action },
});

const getBuildVersion = () => ({
  type: GET_BUILD_HASH_VERSION_REQUEST,
});

const getContractCopy = (id, history) => ({
  type: GET_CONTRACT_COPY_REQUEST,
  payload: { id, history },
});
const getRegenerateContract = (id) => ({
  type: GET_REGENERATE_CONTRACT_FILE_REQUEST,
  payload: id,
});
const setProductValues = (values) => ({
  type: SET_PRODUCT_VALUES,
  payload: values,
});

const setInsurerValues = (values) => ({
  type: SET_INSURER_VALUES,
  payload: values,
});

const setInsuredValues = (values) => ({
  type: SET_INSURED_VALUES,
  payload: values,
});

const setInsuredIsSame = (value) => ({
  type: SET_INSURED_IS_SAME,
  payload: value,
});

const setBeneficiaryValues = () => ({
  type: SET_BENEFICIARY_VALUES,
});

const changeBeneficiaryCount = ({
  count,
  values,
  setValues,
  valueList,
}) => ({
  type: CHANGE_BENEFICIARY_COUNT,
  payload: {
    count,
    values,
    setValues,
    valueList,
  },
});

const getProducts = (id) => ({
  type: GET_PRODUCTS_REQUEST,
  payload: { id },
});

const checkProduct = ({
  id,
  history,
  historyLink,
  scenario,
  qualification,
  isWithoutMasterId,
}) => ({
  type: CHECK_PRODUCT_REQUEST,
  payload: {
    id,
    history,
    historyLink,
    scenario,
    qualification,
    isWithoutMasterId,
  },
});

const getProductForm = (id, constructorProduct, baskedId, history, guaranteeLevel) => ({
  type: GET_PRODUCT_FORM_REQUEST,
  payload: {
    id,
    constructorProduct,
    baskedId,
    guaranteeLevel,
  },
});

const checkSum = ({
  id,
  sumInsured,
  constructorProduct,
  mainRequestId,
  cbSuccess,
  premiumFreq,
}) => ({
  type: CHECK_SUM_REQUEST,
  payload: {
    id,
    sumInsured,
    constructorProduct,
    mainRequestId,
    cbSuccess,
    premiumFreq,
  },
});

const checkPackageService = ({ partnersInsProductId, callback, values }) => ({
  type: CHECK_PACKAGE_SERVICE_REQUEST,
  payload: {
    partnersInsProductId,
    callback,
    values,
  },
});

const saveForms = (values, redirect) => ({
  type: SAVE_FORMS_REQUEST,
  payload: { values, redirect },
});

const getDataContract = (id, history) => ({
  type: GET_DATA_CONTRACT_REQUEST,
  payload: { id },
  history,
});

const saveScan = (params) => ({
  type: SAVE_SCAN_REQUEST,
  payload: params,
});

const getContractFile = ({ ctsId, statusType }) => ({
  type: GET_CONTRACT_FILE_REQUEST,
  payload: { ctsId, statusType },
});

const setFundDescription = (payload) => ({
  type: SET_FUND_DESCRIPTION,
  payload,
});

const resetCtsIds = () => ({ type: RESET_CTS_IDS });

const resetForms = () => ({ type: RESET_FORMS });

const resetErrors = () => ({ type: RESET_ERRORS });

const startValidateTabsBeneficiary = () => ({ type: START_VALIDATE_TABS });
const clearValidTabsBeneficiary = () => ({ type: CLEAR_VALIDATE_TABS });

const getAdditionalDocumentList = (productId) => ({
  type: GET_ADDITIONAL_DOCS_REQUEST,
  payload: productId,
});

const downloadAdditionalDocument = (documentData) => ({
  type: DOWNLOAD_ADDITIONAL_FILE_REQUEST,
  payload: documentData,
});

const setInitStateCheckoutReducer = () => ({
  type: SET_INIT_DATA,
});

const setAnnulStatus = (ctsId) => ({
  type: SET_ANNUL_STATUS.request,
  payload: ctsId,
});

const getSigningType = (ctsId) => ({
  type: GET_SIGNING_TYPE.request,
  payload: ctsId,
});

const setSigningType = (type) => ({
  type: SET_SIGNING_TYPE,
  payload: type,
});

const setIsScanUploadByClient = (value) => ({
  type: SET_IS_SCAN_UPLOAD_BY_CLIENT,
  payload: value,
});

const checkIsActiveConfirmButton = (ctsId) => ({
  type: CHECK_IS_ACTIVE_CONFIRM_BUTTON.request,
  payload: ctsId,
});

const setInitStateSigningType = () => ({
  type: SET_INIT_STATE_SIGNING_TYPE,
});

const setSmsSent = (isSmsSent) => ({
  type: SET_SMS_SENT,
  payload: isSmsSent,
});

const sentSms = (ctsId, isNeedScans) => ({
  type: SENT_SMS.request,
  payload: { ctsId, isNeedScans },
});

const checkNeedTesting = ({
  masterId,
  id,
  history,
  historyLink,
  scenario,
  qualification,
}) => ({
  type: CLIENT_TEST_CHECK_NEED_TESTING_REQUEST,
  payload: {
    masterId,
    id,
    history,
    historyLink,
    scenario,
    qualification,
  },
});

const checkTestStatus = ({
  masterId,
  history,
  historyLink,
}) => ({
  type: CLIENT_TEST_CHECK_TEST_STATUS_REQUEST,
  payload: {
    masterId,
    history,
    historyLink,
  },
});

const updateTestStatus = ({
  id,
  testResult,
  answers,
}) => ({
  type: CLIENT_TEST_UPDATE_TEST_STATUS_REQUEST,
  payload: {
    id,
    testResult,
    answers,
  },
});

const createRecord = ({
  masterId,
  lastName,
  firstName,
  middleName,
  mobileNumber,
}) => ({
  type: CLIENT_TEST_CREATE_RECORD_REQUEST,
  payload: {
    masterId,
    lastName,
    firstName,
    middleName,
    mobileNumber,
  },
});

const updateCreateRecord = ({
  id,
}) => ({
  type: CLIENT_TEST_UPDATE_CREATE_RECORD_REQUEST,
  payload: {
    id,
  },
});

const setRecordFields = ({
  id,
  lastName,
  firstName,
  middleName,
  mobileNumber,
}) => ({
  type: CLIENT_TEST_SET_RECORD_FIELDS,
  payload: {
    clientTestRecordFields: {
      id,
      lastName,
      firstName,
      middleName,
      mobileNumber,
    },
  },
});

const getQuesitons = () => ({
  type: CLIENT_TEST_GET_QUESTIONS_REQUEST,
  payload: {},
});

const checkUserNotBcs = ({
  lastName,
  firstName,
  middleName,
  mobileNumber,
}) => ({
  type: CLIENT_TEST_CHECK_USER_NOTBCS_REQUEST,
  payload: {
    lastName,
    firstName,
    middleName,
    mobileNumber,
  },
});

const checkTestLink = ({ linkToken }) => ({
  type: CLIENT_TEST_CHECK_TEST_LINK_REQUEST,
  payload: {
    linkToken,
  },
});

const getRecordFields = ({ id }) => ({
  type: CLIENT_TEST_GET_RECORD_FIELDS_REQUEST,
  payload: { id },
});

const setClientTestingParams = (payload) => ({
  type: CLIENT_TEST_SET_PARAMS,
  payload,
});

const setClientTestingProduct = ({
  id,
  history,
  historyLink,
  scenario,
  qualification,
  isWithoutMasterId,
}) => ({
  type: CLIENT_TEST_SET_PARAMS,
  payload: {
    clientTestingProduct: {
      id,
      history,
      historyLink,
      scenario,
      qualification,
      isWithoutMasterId,
    },
  },
});

export {
  setAnnulStatus,
  setInitStateCheckoutReducer,
  checkAccountNumbers,
  getAdditionalDocumentList,
  downloadAdditionalDocument,
  changeAutoImportSetting,
  cancelCurrentJob,
  getImportJobs,
  startAutoImport,
  parseXmlFile,
  getBuildVersion,
  getContractCopy,
  setFundDescription,
  clearValidTabsBeneficiary,
  setProductValues,
  setInsurerValues,
  setInsuredValues,
  setInsuredIsSame,
  setBeneficiaryValues,
  changeBeneficiaryCount,
  getProducts,
  checkProduct,
  getProductForm,
  checkSum,
  checkPackageService,
  saveForms,
  resetForms,
  resetErrors,
  getDataContract,
  saveScan,
  getContractFile,
  startValidateTabsBeneficiary,
  getRegenerateContract,
  closePopupImportFileFrom1C,
  fillInsurerDataCheckout,
  fillInsuredDataIsz,
  fillBenefeciaryDataIsz,
  getIszRestrictions,
  getCourseByRefExchangeRatesRegimesCoef,
  setAnnulContract,
  setContractFormed,
  resetCtsIds,
  setEditBasket,
  setCtsIdArr,
  downloadPresentation,
  downloadPresentationConstructor,
  getSigningType,
  setSigningType,
  setIsScanUploadByClient,
  checkIsActiveConfirmButton,
  setInitStateSigningType,
  setSmsSent,
  sentSms,
  checkNeedTesting,
  checkTestStatus,
  createRecord,
  updateCreateRecord,
  updateTestStatus,
  setRecordFields,
  getQuesitons,
  checkUserNotBcs,
  checkTestLink,
  getRecordFields,
  setClientTestingParams,
  setClientTestingProduct,
};
