import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { SearchInput } from 'components';
import { TextInput } from 'components/_deprecated';
import { LANG_DICTIONARY, MASK, INPUT_NAMES } from 'consts';
import { getInputProps } from 'helpers';
import _ from 'lodash';
import { searchBank, resetSearchBank } from 'redux/rootActions';

const {
  BIC,
  BANK_NAME,
  INN,
  KPP,
  COR_ACCOUNT_SHORT,
  CHECK_ACCOUNT_SHORT,
} = LANG_DICTIONARY;

const {
  inn: innMask,
  kpp: kppMask,
  corAcc: corAccMask,
  checkAcc: checkAccMask,
} = MASK;

const defaultProps = {
  props: {},
  isLoading: false,
  searchResult: null,
  onSearch: null,
  selectSearch: null,
  resetSearch: null,
  setFieldValue: null,
  checkNumbers: () => null,
  inputProps: {},
};

const propTypes = {
  props: PropTypes.shape({
    modifiedProps: PropTypes.func,
  }),
  inputProps: PropTypes.object,
  resetSearch: PropTypes.func,
  selectSearch: PropTypes.func,
  setFieldValue: PropTypes.func,
  checkNumbers: PropTypes.func,
  onSearch: PropTypes.func,
  searchResult: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

class BankBlock extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDisabledFields: false,
    };
  }

  handleResultSelect = async (e, { result }) => {
    const { resetSearch, selectSearch, setFieldValue } = this.props;
    const { data, data: { bic, name: { short } } } = result;
    setFieldValue(INPUT_NAMES.bankBic, bic);
    setFieldValue(INPUT_NAMES.bankName, short);
    selectSearch(data);
    resetSearch();
    this.setState({ isDisabledFields: true });
  };

  handleSearchChange = (e, { value }) => {
    const { onSearch, setFieldValue } = this.props;
    setFieldValue(INPUT_NAMES.bankBic, value);
    onSearch(value);
  };

  handleSearchNameChange = (e, { value }) => {
    const { onSearch, setFieldValue } = this.props;
    setFieldValue(INPUT_NAMES.bankName, value);
    onSearch(value);
  };

  render() {
    const {
      isLoading,
      searchResult,
      inputProps,
      inputProps: {
        onBlur,
        values: {
          bic,
          bankName,
        },
      },
      checkNumbers,
    } = this.props;
    const modProps = {
      ...inputProps,
      onBlur: (e) => {
        onBlur(e);
      },
    };

    const providedInputProps = getInputProps(
      {
        ...this.props,
        inputProps: modProps,
      },
      INPUT_NAMES.bankBic,
    );

    const providedBankInputProps = getInputProps(
      {
        ...this.props,
        inputProps: modProps,
      },
      INPUT_NAMES.bankName,
    );

    const { isDisabledFields } = this.state;

    return (
      <Container>
        <Row>
          <Col md="3" sm="12">
            <SearchInput
              name={INPUT_NAMES.bankBic}
              placeholder={BIC}
              loading={isLoading}
              onResultSelect={this.handleResultSelect}
              onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
              results={searchResult}
              value={bic}
              className="insurerData__searchBar"
              inputProps={providedInputProps}
              required
            />
          </Col>
          <Col md="9" sm="12">
            <SearchInput
              name={INPUT_NAMES.bankName}
              placeholder={BANK_NAME}
              loading={isLoading}
              onResultSelect={this.handleResultSelect}
              onSearchChange={_.debounce(this.handleSearchNameChange, 500, { leading: true })}
              results={searchResult}
              value={bankName}
              className="insurerData__searchBar"
              inputProps={providedBankInputProps}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md="4" sm="12">
            <TextInput
              name={INPUT_NAMES.bankInn}
              placeholder={INN}
              mask={innMask}
              disabled={isDisabledFields}
              required
              {...getInputProps(this.props, INPUT_NAMES.bankInn)}
            />
          </Col>
          <Col md="2" sm="12">
            <TextInput
              name={INPUT_NAMES.bankKpp}
              placeholder={KPP}
              mask={kppMask}
              disabled={isDisabledFields}
              required
              {...getInputProps(this.props, INPUT_NAMES.bankKpp)}
            />
          </Col>
          <Col md="6" sm="12">
            <TextInput
              name={INPUT_NAMES.correctionAccount}
              placeholder={COR_ACCOUNT_SHORT}
              mask={corAccMask}
              disabled={isDisabledFields}
              required
              {...getInputProps(this.props, INPUT_NAMES.correctionAccount)}
            />
          </Col>
        </Row>
        <Row>
          <Col md="8" sm="12">
            <TextInput
              name={INPUT_NAMES.checkingAccount}
              placeholder={CHECK_ACCOUNT_SHORT}
              mask={checkAccMask}
              required
              {...getInputProps(this.props, INPUT_NAMES.checkingAccount)}
              checkNumbers={checkNumbers}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps({ bank: { bankSearchResult, isLoading } }) {
  return {
    searchResult: bankSearchResult,
    isLoading,
  };
}

BankBlock.defaultProps = defaultProps;
BankBlock.propTypes = propTypes;

export default connect(
  mapStateToProps,
  {
    onSearch: searchBank,
    resetSearch: resetSearchBank,
  },
)(BankBlock);
