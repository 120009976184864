export const getSettings = ({
  minValue,
  maxValue,
  value,
}) => {
  const X = Number(String(maxValue)[0]);
  const A = Number(String(minValue)[0]);
  const firstEnterSymbol = value[0];
  const valueLength = String(value).length;

  const isQ = (firstEnterSymbol >= X + 1 && firstEnterSymbol <= A - 1);
  const isP = firstEnterSymbol >= 0 && firstEnterSymbol <= X;
  const isS = firstEnterSymbol >= A && firstEnterSymbol <= 9;
  const isError = valueLength >= 2 || (value < minValue || value > maxValue);

  return {
    isQ,
    isP,
    isS,
    isError,
    valueLength,
  };
};

export const runCoefficientAction = ({
  minValue,
  maxValue,
  inputRefs,
  index,
  value = '',
}) => {
  const {
    isP,
    isS,
    valueLength,
  } = getSettings({ minValue, maxValue, value });

  const maxValueLength = String(maxValue).length;

  if (isS && valueLength >= maxValueLength && inputRefs[index + 1]) {
    return null;
  }

  if (isP && valueLength >= maxValueLength && inputRefs[index + 1]) {
    return null;
  }

  return null;
};
