import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { KEY_NAMES, LANG_DICTIONARY } from 'consts';
import { onlyNumbers, removeLeadingZeros } from 'helpers';
import CoefficientField from './coefficient';
import { getSettings, runCoefficientAction } from './coefficientUtils';
import './styles.scss';

const b = block('partition-coefficient');

const { SPECIFY_DESIRED_ASSET_SHARE, VALUE_MUST_BE_NO_MORE } = LANG_DICTIONARY;

const propTypes = {
  data: PropTypes.array,
  values: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  onChange: PropTypes.func,
  errors: PropTypes.object,
  generalError: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
  onError: PropTypes.func,
};

const defaultProps = {
  data: [],
  values: [],
  errors: {},
  onChange: () => null,
  generalError: '',
  description: '',
  disabled: false,
  showError: false,
  onError: () => null,
};

class PartitionCoefficient extends React.Component {
  refsInput = {};

  handleChange = ({ target: { value } }, index) => {
    const {
      data,
      values,
      onChange,
    } = this.props;

    const orderNumberMax = data[index].maxFrontPartitionCoef ?
      String(data[index].maxFrontPartitionCoef).length : 3;
    const { isQ } = getSettings({
      minValue: 0,
      maxValue: data[index].maxFrontPartitionCoef || 0,
      value,
    });

    if (String(value).length > orderNumberMax) {
      return null;
    }

    if (isQ) {
      return null;
    }

    runCoefficientAction({
      index,
      minValue: 0,
      maxValue: data[index].maxFrontPartitionCoef || 0,
      value,
      inputRefs: this.refsInput,
    });

    const copyValues = [...values];
    copyValues[index] = removeLeadingZeros(onlyNumbers(value));

    onChange(copyValues);

    return null;
  };

  handleSaveRefInput = (el, index) => { this.refsInput[index] = el; };

  handleBlur = ({ target: { value } }, index) => {
    const {
      data,
      onError,
    } = this.props;
    const maxValue = data[index].maxFrontPartitionCoef || 0;

    if (value < 0 || value > maxValue) {
      onError({ index, status: true });

      return null;
    }

    onError({ index, status: false });

    return null;
  };

  handleFocus = (index) => {
    const { onError } = this.props;
    onError({ index, status: false });
  };

  handleKeyDown = (e, index) => {
    const { values } = this.props;
    const ref = this.refsInput[index - 1];
    const value = values[index] || '';
    if (e.key === KEY_NAMES.KEY_BACKSPACE && !String(value).length && ref) {
      ref.focus();
    }
  };

  render() {
    const {
      data,
      values,
      disabled,
      errors,
      generalError,
      description,
      showError,
    } = this.props;
    const coefficientsTemplate = [...Array(data.length).keys()];

    return (
      <div className={b()}>
        <div className={b('coefs')}>
          <div className={b('title')}>{SPECIFY_DESIRED_ASSET_SHARE}</div>
          {coefficientsTemplate.map((item, index) => (
            <div className={b('coef-wrapper')}>
              <div className={b('coef-input')} key={`${item}coef`}>
                <CoefficientField
                  name={`${item}coef`}
                  index={index}
                  value={values[index] || '0'}
                  saveRefInput={this.handleSaveRefInput}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  onFocus={this.handleFocus}
                  onKeyDown={this.handleKeyDown}
                  disabled={disabled}
                  isError={errors[index]}
                />
                <span className={b('percent')}>%</span>
                <input disabled value={data[index].basicAssetsText} />
                <Popup
                  content={data[index].basicAssetsDescription}
                  trigger={<Icon className={b('icon')} size="large" name="info circle" />}
                  basic
                />
              </div>
              {errors[index] && (
                <p className={b('error')}>
                  {`${VALUE_MUST_BE_NO_MORE} ${data[index].maxFrontPartitionCoef || 0}`}
                </p>
              )}
            </div>
          ))}
        </div>
        {showError && <p className={b('error')}>{generalError}</p>}
        {coefficientsTemplate.length > 0 && (<p className={b('error')}>{description}</p>)}
      </div>
    );
  }
}
PartitionCoefficient.propTypes = propTypes;
PartitionCoefficient.defaultProps = defaultProps;
export default PartitionCoefficient;
