import {
  GET_PARTNERS_FOR_TABLE_FAIL,
  GET_PARTNERS_FOR_TABLE_START,
  GET_PARTNERS_FOR_TABLE_SUCCESS,
  GET_PARTNER_DATA_SUCCESS,
  CLEAR_EDITOR_FORM,
  SET_PAGE_PAGINATION,
  ADD_NEW_IP,
  DEL_IP,
  CHANGE_ACCESS,
} from '../types';

const INITIAL_EDITOR_FORM = {
  codeValue: '',
  contractNumber: '',
  contrAgent: '',
  dateContract: null,
  fullNaming: '',
  id: '',
  inn: '',
  legalType: '',
  linkLogo: '',
  ogrn: '',
  shortNaming: '',
  includePartner: false,
  typeContract: '',
  showOnSite: false,
  actived: false,
  isRulesAccepting: false,
  isReportsAvailable: false,
  isPartnerRefersToBcsSk: false,
};

const INITIAL_STATE = {
  partnerFormData: INITIAL_EDITOR_FORM,
  loadingTableInfo: false,
  tableInfo: [],
  page: 1,
  totalPages: 1,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_PARTNERS_FOR_TABLE_SUCCESS:
      return {
        ...state,
        loadingTableInfo: false,
        tableInfo: payload.data,
        page: payload.currentPage,
        totalPages: payload.totalPages,
      };
    case GET_PARTNERS_FOR_TABLE_FAIL:
      return {
        ...state,
        loadingTableInfo: false,
      };
    case GET_PARTNERS_FOR_TABLE_START:
      return {
        ...state,
        loadingTableInfo: true,
      };

    case GET_PARTNER_DATA_SUCCESS: {
      const {
        codeValue,
        contractNumber,
        contrAgent,
        dateContract,
        fullNaming,
        id,
        inn,
        legalType,
        linkLogo,
        ogrn,
        shortNaming,
        includePartner,
        typeContract,
        showOnSite,
        actived,
        isRulesAccepting,
        isReportsAvailable,
        isPartnerRefersToBcsSk,
        ipAddresses,
      } = payload;

      const rebuildIpAddresses = ipAddresses !== null ? ipAddresses.map((item, index) => ({
        key: index + 1,
        value: item,
      })) : [];

      return {
        ...state,
        partnerFormData: {
          codeValue: codeValue || '',
          contractNumber: contractNumber || '',
          contrAgent: contrAgent || '',
          dateContract: dateContract ? new Date(dateContract) : null,
          fullNaming: fullNaming || '',
          id,
          inn: inn || '',
          legalType: legalType || '',
          linkLogo: linkLogo || '',
          ogrn: ogrn || '',
          shortNaming: shortNaming || '',
          includePartner: !!includePartner,
          typeContract: typeContract || '',
          showOnSite: !!showOnSite,
          actived: !!actived,
          isRulesAccepting,
          isReportsAvailable,
          isPartnerRefersToBcsSk,
          ipAddresses: ipAddresses === null ? [] : rebuildIpAddresses,
          ipAddressesCheckbox: ipAddresses === null,
        },
      };
    }

    case CLEAR_EDITOR_FORM:
      return {
        ...state,
        partnerFormData: INITIAL_EDITOR_FORM,
      };

    case SET_PAGE_PAGINATION:
      return {
        ...state,
        page: payload,
      };

    case ADD_NEW_IP: {
      const key = state.partnerFormData.ipAddresses.length + 1;

      return {
        ...state,
        partnerFormData: {
          ...state.partnerFormData,
          ipAddresses: [...state.partnerFormData.ipAddresses, { key, value: payload }],
        },
      };
    }

    case DEL_IP: {
      const rebuildIpAddresses = state.partnerFormData.ipAddresses.filter(
        (item) => item.key !== payload,
      );

      return {
        ...state,
        partnerFormData: {
          ...state.partnerFormData,
          ipAddresses: rebuildIpAddresses,
        },
      };
    }

    case CHANGE_ACCESS: {
      return {
        ...state,
        partnerFormData: payload,
      };
    }

    default:
      return state;
  }
};
