import { PRODUCT_VERSION } from 'consts';
import insurer from '../commonData/insurer';
import bankData from '../commonData/bankData';
import insured from '../commonData/insured';
import beneficiaries from '../commonData/beneficiaries';

const dataToScenario = {
  [PRODUCT_VERSION.normal]: {
    insurer: {
      ...insurer,
      ...bankData,
    },
    insured: {
      ...insured,
      ...bankData,
    },
    beneficiaries,
  },
  [PRODUCT_VERSION.coupon]: {
    insurer: {
      ...insurer,
      ...bankData,
    },
    insured: {
      ...insured,
      ...bankData,
    },
    beneficiaries,
  },
  [PRODUCT_VERSION.normalCurrency]: {
    insurer: {
      ...insurer,
      ...bankData,
    },
    insured: {
      ...insured,
      ...bankData,
    },
    beneficiaries,
  },
  [PRODUCT_VERSION.couponCurrency]: {
    insurer: {
      ...insurer,
      ...bankData,
    },
    insured: {
      ...insured,
      ...bankData,
    },
    beneficiaries,
  },
  [PRODUCT_VERSION.constructorIsz]: {
    insurer: {
      ...insurer,
      ...bankData,
    },
    insured: {
      ...insured,
      ...bankData,
    },
    beneficiaries,
  },
  [PRODUCT_VERSION.constructorIszDollar]: {
    insurer: {
      ...insurer,
      ...bankData,
    },
    insured: {
      ...insured,
      ...bankData,
    },
    beneficiaries,
  },
};

export default (scenario) => dataToScenario[scenario];
