import React from 'react';
import PropTypes from 'prop-types';
import {
  MainBtn,
  RequiredLabel,
  InputField,
  CustomCheckBox,
} from 'components';
import { Icon } from 'semantic-ui-react';
import { Formik } from 'formik';
import { LANG_DICTIONARY } from 'consts';
import { block } from 'bem-cn';
import './styles.scss';
import schema from './schema';

const b = block('creating-partner-form');

const {
  ID,
  CONTRAGENT_NAMING,
  SHORT_NAMING,
  FULL_NAMING,
  CODE_VALUE,
  LEGAL_TYPE,
  INN,
  OGRN_OR_OGRNIP,
  LINK_LOGO,
  CONTRACT_NUMBER,
  DATE_CONTRACT,
  INCLUDE_PARTNER,
  TYPE_CONTRACT,
  SHOW_ON_SITE_LIST_AGENT,
  ACTIVED,
  CREATE,
  REVOKE,
  SAVE_WORD,
  IS_NEED_ACCEPT_RULES,
  AVAILABILITY_OF_CLIENT_REPORTS,
  IS_PARTNER_REFERS_TO_BCS_SZ,
  IS_ACCESS_WITHOUT_IP_RESTRICTIONS,
  ADD_IP_ADDRESS,
  IP_ADDRESSES_LIST,
} = LANG_DICTIONARY;

const propTypes = {
  initialValues: PropTypes.object,
  history: PropTypes.object,
  handleSubmitSuccess: PropTypes.func,
  isUpdatedForm: PropTypes.bool,
  addNewIPAddress: PropTypes.func,
  delIpAddress: PropTypes.func,
  changeUnlimitedAccess: PropTypes.func,
};

const defaultProps = {
  initialValues: {},
  history: {},
  handleSubmitSuccess: () => null,
  isUpdatedForm: false,
  addNewIPAddress: () => null,
  delIpAddress: () => null,
  changeUnlimitedAccess: () => null,
};

const inputsList = [
  { name: 'id', title: ID, disabled: true },
  { name: 'contrAgent', title: CONTRAGENT_NAMING, required: true },
  { name: 'shortNaming', title: SHORT_NAMING, required: true },
  { name: 'fullNaming', title: FULL_NAMING, required: true },
  { name: 'codeValue', title: CODE_VALUE, required: true },
  { name: 'legalType', title: LEGAL_TYPE, required: true },
  {
    name: 'inn',
    title: INN,
    mask: '999999999999',
  },
  { name: 'ogrn', title: OGRN_OR_OGRNIP },
  { name: 'linkLogo', title: LINK_LOGO },
  { name: 'contractNumber', title: CONTRACT_NUMBER },
];

const CreatingOrUpdatingPartnerForm = ({
  initialValues,
  history,
  handleSubmitSuccess,
  isUpdatedForm,
  addNewIPAddress,
  delIpAddress,
  changeUnlimitedAccess,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={schema}
    onSubmit={(values) => handleSubmitSuccess(values)}
    enableReinitialize
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
    }) => {
      const inputProps = {
        values,
        errors,
        touched,
        onChange: handleChange,
        onBlur: handleBlur,
        prefixWrapperClass: b('scroll').toString(),
      };

      const handleChangeCheckbox = (name, checked) => {
        handleChange({ target: { name, value: !checked } });
      };

      const handleChangeUnlimitedAccess = () => {
        changeUnlimitedAccess({ ...values, ipAddressesCheckbox: !values.ipAddressesCheckbox });
      };

      const handleChangeIp = (name, value) => {
        handleChange({ target: { name, value } });
      };

      const addNewIP = () => {
        addNewIPAddress(values.ipAddress);
        handleChange({ target: { name: 'ipAddress', value: '' } });
      };

      const delIp = (key) => {
        delIpAddress(key);
      };

      return (
        <form className={b()}>
          <RequiredLabel />
          {inputsList.map(({
            name,
            title,
            disabled,
            required,
            mask,
          }) => (
            <InputField
              name={name}
              disabled={disabled}
              required={required}
              title={title}
              errors={errors}
              touched={touched}
              mask={mask}
              key={title}
              {...inputProps}
            />
          ))}
          <InputField
            {...inputProps}
            title={DATE_CONTRACT}
            name="dateContract"
            type="date"
          />
          <InputField
            {...inputProps}
            title={INCLUDE_PARTNER}
            type="checkbox"
            name="includePartner"
            onChange={handleChangeCheckbox}
          />
          <InputField
            {...inputProps}
            name="typeContract"
            title={TYPE_CONTRACT}
            errors={errors}
            touched={touched}
          />
          <InputField
            {...inputProps}
            title={SHOW_ON_SITE_LIST_AGENT}
            type="checkbox"
            name="showOnSite"
            onChange={handleChangeCheckbox}
          />
          <InputField
            {...inputProps}
            title={ACTIVED}
            type="checkbox"
            name="actived"
            onChange={handleChangeCheckbox}
          />
          <InputField
            {...inputProps}
            title={IS_NEED_ACCEPT_RULES}
            type="checkbox"
            name="isRulesAccepting"
            onChange={handleChangeCheckbox}
          />
          <InputField
            {...inputProps}
            title={AVAILABILITY_OF_CLIENT_REPORTS}
            type="checkbox"
            name="isReportsAvailable"
            onChange={handleChangeCheckbox}
          />
          <InputField
            {...inputProps}
            title={IS_PARTNER_REFERS_TO_BCS_SZ}
            type="checkbox"
            name="isPartnerRefersToBcsSk"
            onChange={handleChangeCheckbox}
          />
          <div className={b('ip-addresses-block')}>
            <p className={b('ip-addresses-text')}>{IS_ACCESS_WITHOUT_IP_RESTRICTIONS}</p>
            <CustomCheckBox
              name="ipAddressesCheckbox"
              onChange={handleChangeUnlimitedAccess}
              checked={values.ipAddressesCheckbox}
            />
            {
              !values.ipAddressesCheckbox && (
              <>
                <input
                  className={b('ip-addresses-input')}
                  name="ipAddress"
                  value={values.ipAddress}
                  onChange={(e) => handleChangeIp('ipAddress', e.target.value)}
                />
                <button
                  type="button"
                  className={b('ip-addresses-button')}
                  disabled={!values.ipAddress}
                  onClick={addNewIP}
                >
                  {ADD_IP_ADDRESS}
                </button>
              </>
              )
            }
          </div>
          {
            !values.ipAddressesCheckbox && (
            <div className={b('ip-list-block')}>
              <p className={b('ip-list-text')}>{IP_ADDRESSES_LIST}</p>
              <div className={b('ip-addresses-list')}>
                {
                  values.ipAddresses && values.ipAddresses.map((item) => (
                    <div className={b('ip-address')}>
                      <div>{item.value}</div>
                      <div>
                        <Icon name="delete" onClick={() => delIp(item.key)} />
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            )
}
          <div className={b('controls')}>
            <MainBtn
              text={REVOKE}
              onClick={() => history.goBack({ cache: true })}
            />
            <MainBtn
              text={isUpdatedForm ? SAVE_WORD : CREATE}
              onClick={handleSubmit}
            />
          </div>
        </form>
      );
    }}
  </Formik>
);

CreatingOrUpdatingPartnerForm.propTypes = propTypes;
CreatingOrUpdatingPartnerForm.defaultProps = defaultProps;
export default CreatingOrUpdatingPartnerForm;
